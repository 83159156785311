<div class="d-flex flex-column justify-content-center align-items-center" mat-dialog-content>
  <img class="mb-2" src="assets/emoji/TrashCan.svg" />
  <h1 class="fut-h1 mb-2 text-center">{{ data.title | transloco }}</h1>
  <h3 class="fut-h3 text-muted mb-2">{{ data.subtitle | transloco }}</h3>
  <span class="mb-2 text-center" style="max-width: 70%">{{ data.content }}</span>
</div>

<div class="d-flex justify-content-end align-items-center" mat-dialog-actions>
  <button class="fut-btn fut-btn-link mr-2" mat-dialog-close>{{ data.exit_button | transloco }}</button>
  <button class="fut-btn fut-btn-danger" mat-dialog-close="true">{{ data.delete_button | transloco }}</button>
</div>
