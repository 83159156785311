import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

export interface ConfirmDeleteDialogData {
  title: string;
  subtitle: string;
  content: string;
  delete_button: string;
  exit_button: string;
}

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss'],
  standalone: true,
  imports: [TranslocoModule, MatDialogContent, MatDialogActions, MatDialogClose],
})
export class ConfirmDeleteDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteDialogData) {}
}
