import { CacheObservable, CallCache } from './model';
import { forkJoin, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { delay, finalize, map, tap } from 'rxjs/operators';

export class CallCacheSimple<T> implements CallCache<T> {
  private internalResponse?: T;
  private error?: unknown;

  private subscription?: Subscription;
  private tmpResponse?: ReplaySubject<T>;

  constructor(
    private readonly call: () => Observable<T>,
    private readonly tryAgainOnError = false
  ) {}

  public get response(): Observable<T> {
    if (this.error) {
      return new Observable<T>(subscriber => subscriber.error(this.error));
    }

    if (this.internalResponse) {
      return of(this.internalResponse);
    }

    if (!this.tmpResponse) {
      this.tmpResponse = this.prepareCall();
    }

    return this.tmpResponse.asObservable();
  }

  public update(value: T): Observable<T> {
    this.tmpResponse?.next(value);
    this.reset();
    this.internalResponse = value;
    return this.response;
  }

  public reset(): void {
    this.error = undefined;
    this.internalResponse = undefined;
    this.subscription?.unsubscribe();
    this.subscription = undefined;
    try {
      this.tmpResponse?.complete();
    } catch (e) {
      // Nope
    }
    this.tmpResponse = undefined;
  }

  private prepareCall(): ReplaySubject<T> {
    const tmpResponse = new ReplaySubject<T>(1);
    this.tmpResponse = tmpResponse;
    this.subscription = this.call()
      .pipe(
        finalize(() => {
          this.subscription = undefined;
          this.tmpResponse = undefined;
        })
      )
      .subscribe({
        next: value => {
          tmpResponse?.next(value);
          tmpResponse?.complete();
          this.internalResponse = value;
        },
        error: error => {
          tmpResponse?.error(error);
          if (this.tryAgainOnError) {
            this.tmpResponse = undefined;
          } else {
            this.error = error;
          }
        },
      });

    return tmpResponse;
  }
}

export class CacheObservableSimple<K, V> implements CacheObservable<K, V> {
  private readonly cache = new Map<K, CallCacheSimple<V>>();
  private readonly timerCache?: Map<K, ReturnType<typeof setTimeout>>;

  /**
   * @param {number} [expireTime] - The expiration time in milliseconds.
   * @param {number} [responseDelay] - The cached response delay in milliseconds.
   */
  constructor(
    private readonly expireTime?: number,
    private readonly responseDelay?: number
  ) {
    if (this.expireTime) {
      this.timerCache = new Map<K, ReturnType<typeof setTimeout>>();
    }
  }

  public get(key: K, call: () => Observable<V>, tryAgainOnError = false): Observable<V> {
    return this.getCall(key) ?? this.addCall(key, call, tryAgainOnError);
  }

  public update(key: K, value: V): Observable<V> {
    const cachedValue = this.cache.get(key);
    if (!cachedValue) {
      console.warn(`Cache with key ${key} not found`);
      return of(value);
    }
    const response = cachedValue.update(value);
    if (!this.timerCache) {
      return response;
    }
    const timeout = this.timerCache.get(key);
    if (timeout) {
      clearTimeout(timeout);
      this.timerCache?.delete(key);
    }
    this.setExpireLogic(key, cachedValue);
    return response;
  }

  public clear(): void {
    this.cache.forEach(value => value.reset());
    this.cache.clear();
    if (!this.timerCache) {
      return;
    }
    this.timerCache.forEach(value => clearTimeout(value));
  }

  private addCall(key: K, call: () => Observable<V>, tryAgainOnError = false): Observable<V> {
    this.cache.get(key)?.reset();
    const timer = this.timerCache?.get(key);

    if (timer != null) {
      clearTimeout(timer);
      this.timerCache?.delete(key);
    }

    const callCache = new CallCacheSimple(call, tryAgainOnError);
    this.cache.set(key, callCache);
    return this.getObservable(key, callCache);
  }

  private getCall(key: K): undefined | Observable<V> {
    const call = this.cache.get(key);
    if (!call) {
      return undefined;
    }
    return this.getObservable(key, call);
  }

  private getObservable(key: K, call: CallCacheSimple<V>): Observable<V> {
    const cachedResponse = call.response.pipe(
      tap(() => {
        this.setExpireLogic(key, call);
      })
    );
    if (this.responseDelay) {
      return forkJoin([cachedResponse, of('delay').pipe(delay(this.responseDelay))]).pipe(map(([r]) => r));
    }
    return cachedResponse;
  }

  private setExpireLogic(key: K, call: CallCacheSimple<V>) {
    if (!this.expireTime) {
      return;
    }

    if (!this.timerCache) {
      return;
    }
    const timerCache = this.timerCache;
    if (timerCache.has(key)) {
      return;
    }
    const timeout = setTimeout(() => {
      call?.reset();
      timerCache.delete(key);
    }, this.expireTime);
    timerCache.set(key, timeout);
  }
}
