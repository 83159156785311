import { CacheObservable } from './cache/model';
import { CacheObservablePersistence, MemoryType } from './cache/persistence.util';
import { CacheObservableSimple } from './cache/simple.util';

export class CacheBuilder<K, V> {
  private expireTime?: number;
  private responseDelay?: number;

  private constructor(private readonly memoryType?: MemoryType) {}

  /**
   * It will create an in memory cache. It will delete after a page reload
   */
  public static simple<K, V>(): CacheBuilder<K, V> {
    return new CacheBuilder<K, V>();
  }

  /**
   * It will create a persistence cache. It will not delete after a page reload
   * @param {MemoryType} [memoryType] - The browser memory where store data
   */
  public static persistence<K, V>(memoryType: MemoryType = 'localStorage'): CacheBuilder<K, V> {
    return new CacheBuilder<K, V>(memoryType);
  }

  /**
   *
   * @param {number} [expireTime] - The expiration time in milliseconds.
   */
  public setExpireTime(expireTime: number): CacheBuilder<K, V> {
    this.expireTime = expireTime;
    return this;
  }

  /**
   *
   * @param {number} [responseDelay] - The cached response delay in milliseconds.
   */
  public setResponseDelay(responseDelay: number): CacheBuilder<K, V> {
    this.responseDelay = responseDelay;
    return this;
  }

  public build(): CacheObservable<K, V> {
    if (!this.memoryType) {
      return new CacheObservableSimple(this.expireTime, this.responseDelay);
    }
    return new CacheObservablePersistence(this.expireTime, this.responseDelay, this.memoryType);
  }
}
