export function deepCopy<T>(o: T): T {
  if (o == null) {
    return o;
  }
  return JSON.parse(JSON.stringify(o)) as T;
}

export function deepCompare(o1: unknown, o2: unknown): boolean {
  if (o2 == null) {
    if (o1 == null) {
      return o2 === o1;
    }
    return false;
  }
  return JSON.stringify(o1) === JSON.stringify(o2);
}
