import { CacheObservable, CallCache } from './model';
import { forkJoin, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { delay, finalize, map } from 'rxjs/operators';

interface PersistenceBaseModel<T> {
  error?: unknown;
  content?: T;
}

interface PersistenceErrorModel<T> extends PersistenceBaseModel<T> {
  error: unknown;
}

interface PersistenceModel<T> extends PersistenceBaseModel<T> {
  content: T;
}

export type MemoryType = 'localStorage' | 'sessionStorage';

function typeToStorage(memoryType: MemoryType): Storage {
  switch (memoryType) {
    case 'localStorage':
      return localStorage;
    case 'sessionStorage':
      return sessionStorage;
    default:
      throw new Error(`Unsupported memoryType: ${memoryType}`);
  }
}

function createCacheKey<K>(key: K): string {
  return `__fut_persistence_cache_${key}`;
}

const CACHE_UNIQUE_KEY_CHECK = new Set<string>();

export class CallCachePersistence<T> implements CallCache<T> {
  private readonly storage: Storage;
  private readonly cacheKey: string;

  private subscription?: Subscription;
  private tmpResponse?: ReplaySubject<T>;

  constructor(
    public readonly key: string,
    private readonly call: () => Observable<T>,
    private readonly tryAgainOnError = false,
    readonly memoryType: MemoryType = 'localStorage'
  ) {
    this.cacheKey = createCacheKey(key);
    if (CACHE_UNIQUE_KEY_CHECK.has(this.cacheKey)) {
      throw new Error(`Found duplicate key: ${key}`);
    }
    CACHE_UNIQUE_KEY_CHECK.add(this.cacheKey);

    this.storage = typeToStorage(memoryType);
  }

  public get response(): Observable<T> {
    const cachedResponse = this.readFromStorage();
    if (cachedResponse) {
      if (cachedResponse.content) {
        return of(cachedResponse.content);
      }
      if (cachedResponse.error) {
        return new Observable<T>(subscriber => subscriber.error(cachedResponse.error));
      }
    }

    if (!this.tmpResponse) {
      this.tmpResponse = this.prepareCall();
    }

    return this.tmpResponse.asObservable();
  }

  public update(value: T): Observable<T> {
    this.tmpResponse?.next(value);
    this.reset();
    this.writeOnStorage({ content: value });
    return this.response;
  }

  reset(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
    try {
      this.tmpResponse?.complete();
    } catch (e) {
      // Nope
    }
    this.tmpResponse = undefined;
    this.storage.removeItem(this.cacheKey);
  }

  private prepareCall(): ReplaySubject<T> {
    const tmpResponse = new ReplaySubject<T>(1);
    this.tmpResponse = tmpResponse;
    this.subscription = this.call()
      .pipe(
        finalize(() => {
          this.subscription = undefined;
          this.tmpResponse = undefined;
        })
      )
      .subscribe({
        next: value => {
          tmpResponse?.next(value);
          tmpResponse?.complete();
          this.writeOnStorage({ content: value });
        },
        error: error => {
          tmpResponse?.error(error);
          if (this.tryAgainOnError) {
            this.tmpResponse = undefined;
          } else {
            this.writeOnStorage({ error });
          }
        },
      });

    return tmpResponse;
  }

  private readFromStorage(): PersistenceErrorModel<T> | PersistenceModel<T> | null {
    const rawData = this.storage.getItem(this.cacheKey);

    if (!rawData) {
      return null;
    }

    return JSON.parse(rawData);
  }

  private writeOnStorage(result: PersistenceErrorModel<T> | PersistenceModel<T>): void {
    const rawData = JSON.stringify(result);
    this.storage.setItem(this.cacheKey, rawData);
  }
}

export class CacheObservablePersistence<K, V> implements CacheObservable<K, V> {
  private readonly cache = new Map<K, CallCachePersistence<V>>();
  private readonly storage: Storage;

  /**
   * @param {number} [expireTime] - The expiration time in milliseconds.
   * @param {number} [responseDelay] - The cached response delay in milliseconds.
   * @param {MemoryType} [memoryType] - The browser memory where store data
   */
  constructor(
    private readonly expireTime?: number,
    private readonly responseDelay?: number,
    private readonly memoryType: MemoryType = 'localStorage'
  ) {
    this.storage = typeToStorage(memoryType);
  }

  private static getExpireKey<K>(key: K): string {
    return `${createCacheKey(key)}_expire`;
  }

  get(key: K, call: () => Observable<V>, tryAgainOnError?: boolean): Observable<V> {
    const cachePersistence = this.getOrCreate(key, call, tryAgainOnError);
    const expireDate = this.getExpireDate(key);

    if (expireDate && expireDate < new Date()) {
      cachePersistence.reset();
      this.initExpireLogic(key, true);
    }

    const response = cachePersistence.response;
    if (this.responseDelay) {
      return forkJoin([response, of('delay').pipe(delay(this.responseDelay))]).pipe(map(([r]) => r));
    }
    return response;
  }

  update(key: K, value: V): Observable<V> {
    const cachedValue = this.cache.get(key);
    if (!cachedValue) {
      console.warn(`Cache with key ${key} not found`);
      return of(value);
    }
    const response = cachedValue.update(value);
    this.initExpireLogic(key, true);
    return response;
  }

  clear(): void {
    this.cache.forEach(value => {
      const key = value.key;
      this.storage.removeItem(CacheObservablePersistence.getExpireKey(key));
      value.reset();
      CACHE_UNIQUE_KEY_CHECK.delete(createCacheKey(key));
    });
    this.cache.clear();
  }

  private getOrCreate(key: K, call: () => Observable<V>, tryAgainOnError?: boolean): CallCachePersistence<V> {
    const cachedValue = this.cache.get(key);
    if (cachedValue) {
      return cachedValue;
    }
    const cachePersistence = new CallCachePersistence<V>(`${key}`, call, tryAgainOnError, this.memoryType);
    this.cache.set(key, cachePersistence);
    this.initExpireLogic(key);
    return cachePersistence;
  }

  private initExpireLogic(key: K, update = false): void {
    if (!this.expireTime || this.expireTime <= 0) {
      return;
    }
    if (!update) {
      const oldDate = this.getExpireDate(key);
      if (oldDate) {
        return;
      }
    }

    const expireKey = CacheObservablePersistence.getExpireKey(key);

    const expireDate = new Date();
    expireDate.setMilliseconds(expireDate.getMilliseconds() + this.expireTime);
    this.storage.setItem(expireKey, JSON.stringify(expireDate));
  }

  private getExpireDate(key: K): Date | null {
    const expireKey = CacheObservablePersistence.getExpireKey(key);
    const rawDate = this.storage.getItem(expireKey);
    if (!rawDate) {
      return null;
    }

    return new Date(JSON.parse(rawDate));
  }
}
